import React, { Component } from 'react'

export default class Home extends Component {
    constructor() {
        super();
        this.state = {
          fondo: "React"
        };
      }
    render() {
        return (
            
            <div className="home-entrance">
            <h2>Web Development</h2>
              </div>
        )
    }
}
